<section>
  <div class="description">
    <span class="description-text"
      >Estamos especializados na implantación de
      <strong style="color: #6cace4">solucións integrais TIC.</strong></span
    >
  </div>
  <div class="container">
    <div class="cloud-img">
      <img src="/assets/IMG/CloudPBX/cloud-logo.png" alt="CloudPBX" />
    </div>
    <div class="support-container">
      <div class="pack-container">
        <app-cloudpbx-card
          [cloud_pbx_tariff]="cloud_pbx_tariff"
          *ngFor="let cloud_pbx_tariff of cloud_pbx"
        >
        </app-cloudpbx-card>
      </div>
    </div>
  </div>
</section>

<app-our-services></app-our-services>
