<div class="cart" cdkDrag *ngIf="showCart">
  <button mat-icon-button class="close-card" (click)="closeCart()">
    <mat-icon>clear</mat-icon>
  </button>

  <div class="cart-header" cdkDragHandle>
    <p>Os teus productos</p>
  </div>

  <div class="divider-pink"></div>

  <div class="pack-list">
    <div *ngFor="let pack of packs">
      <div class="pack-price">
        <span class="title">
          {{ pack.name }}
        </span>
        <div class="subtotal">
          <span class="units">{{ pack.units + " x " + pack.price + "€" }}</span>
          <span class="final-price">{{ getSubtotal(pack) }}€ </span>
        </div>
        <button mat-icon-button color="warn" (click)="removePack(pack)">
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
      <div>
        <p *ngIf="pack.type !== 'fibra'">
          {{
            pack.items.lines === 1
              ? "Una linea movil"
              : pack.items.lines + "
                    lineas moviles"
          }}
          <span *ngIf="pack.items.mobileData">
            con {{ pack.items.mobileData }}</span
          >
        </p>
        <p *ngIf="pack.type !== 'movil'">
          Fibra Simetrica de {{ pack.items.fiberSpeed }} MB
        </p>
        <p *ngIf="pack.items.landline">Fijo con llamadas ilimitadas</p>
      </div>
    </div>
  </div>
  <div *ngIf="cloudPBX" class="cloudpbx-container">
    <h5 class="company-services">Servicios Empresas</h5>
    <div class="pack-price">
      <span class="title">
        {{ cloudPBX.name }}
      </span>
      <div class="subtotal">
        <span class="final-price">{{ cloudPBX.price }}€ </span>
      </div>
      <button mat-icon-button color="warn" (click)="removeCloudPBX()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <div class="total">
    <h3>Total (IVA Incluido):</h3>
    <h1>{{ getTotal() }} €/MES</h1>
  </div>

  <div class="submit">
    <div class="divider-pink" style="max-width: 25%"></div>
    <button
      style="color: white"
      type="button"
      mat-raised-button
      color="accent"
      [disabled]="packs.length === 0"
      (click)="showForm = !showForm"
    >
      {{ !showForm || packs.length === 0 ? "Contratar" : "Cancelar" }}
    </button>
    <div class="divider-pink" style="max-width: 25%"></div>
  </div>
  <p class="tip" *ngIf="!showForm">
    Tip: Desde o Home podes mover o carriño pola pantalla para maior comodidade
  </p>

  <ng-container *ngIf="showForm && packs.length > 0">
    <form [formGroup]="contractForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="full-width-control">
        <mat-label>Nombre</mat-label>
        <input type="text" matInput formControlName="name" />
        <mat-error *ngIf="f.name.hasError('required')">
          Por favor, ingresa o teu nome para continuar
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width-control">
        <mat-label>Email</mat-label>
        <input
          type="email"
          matInput
          formControlName="email"
          placeholder="Ej: nombre@empresa.com"
        />
        <mat-error
          *ngIf="f.email.hasError('email') && !f.email.hasError('required')"
        >
          Ingrese un email válido
        </mat-error>
        <mat-error *ngIf="f.email.hasError('required')">
          Por favor, ingresa o teu email para continuar
        </mat-error>
      </mat-form-field>

      <mat-form-field class="full-width-control">
        <mat-label>Codigo Postal</mat-label>
        <input
          matInput
          formControlName="zip"
          maxlength="5"
          placeholder="Ej: 46001"
        />
        <mat-hint align="end" *ngIf="f.zip.value"
          >{{ f.zip.value.length }} / 5</mat-hint
        >
      </mat-form-field>
      <mat-form-field class="full-width-control">
        <mat-label>Teléfono</mat-label>
        <span matPrefix>+34 &nbsp;</span>
        <input
          type="tel"
          formControlName="phone"
          matInput
          placeholder="666-555-4444"
        />
        <mat-icon matSuffix>call</mat-icon>
      </mat-form-field>
      <div class="submit">
        <button
          type="submit"
          mat-raised-button
          color="accent"
          [disabled]="disableConfirmButton"
          style="color: white"
        >
          Confirmar
        </button>
      </div>
    </form>
  </ng-container>
</div>
