<section>
  <div class="appmobile-container">
    <h1 data-aos="fade-down" data-aos-duration="1500">
      DESCARGA <strong style="color: #6cace4">A NOSA APP</strong>
    </h1>

    <div class="fondo-moviles">
      <img
        src="/assets/IMG/movil_app_celta1.png"
        alt=""
        class="app-mobile-image"
      />

      <!-- <img src="./assets/IMG/movil_app_celta2.png" alt="App para particulares" class="app-mobile-image"
                *ngIf="webParticular;else empresasapp">-->
      <div class="playstore">
        <a
          href="https://play.google.com/store/apps/details?id=com.NexoTelecom.CeltaMobile"
          target="_blank"
          ><img src="/assets/IMG/google-play.png" alt="playstore"
        /></a>
        <img class="qr-code" src="/assets/IMG/qr-app.png" alt="qr-code" />
      </div>
    </div>
  </div>
</section>
<ng-template #empresasapp>
  <img
    src="/assets/IMG/movil_app3.png"
    alt="App para empresas"
    class="app-mobile-image"
  />
</ng-template>
