<section class="container-aboutus">
  <h1 class="main-title" data-aos="fade-down" data-aos-duration="2500">
    Sobre <strong id="principios-word">nosotros</strong>
  </h1>
  <div class="divider" data-aos="fade-left" data-aos-duration="2500"></div>

  <div class="aboutus-box">
    <div class="aboutus-boxes">
      <div class="title">
        <i class="bi-lightning-charge plus-icon"></i>
        <span> Entradas VIP </span>
      </div>
      <p>Sorteamos entradas VIP para todos os partidos. Só para clientes!</p>
    </div>

    <div class="aboutus-boxes">
      <div class="title">
        <i class="bi-lightning-charge plus-icon"></i>
        <span> Liberdade </span>
      </div>
      <p>Con Celta Mobile es libre, sen ataduras. <br /></p>
      <p style="font-size: 12px">
        *(As tarifas de Fibra + móbil son de permanencia de 12 meses)
      </p>
    </div>

    <div class="aboutus-boxes">
      <div class="title">
        <i class="bi-lightning-charge plus-icon"></i>
        <span> Atención 24/7 </span>
      </div>
      <p>En calquera momento, para calquera dúbida.</p>
    </div>

    <div class="aboutus-boxes">
      <div class="title">
        <i class="bi-lightning-charge plus-icon" svgIcon="grade"></i>
        <span> Adaptámonos </span>
      </div>
      <p>
        Adaptámonos ás túas necesidades. <br />
        Non pagues por servizos que non utilizas.
      </p>
    </div>
  </div>

  <div class="slider-container"></div>
</section>
