<app-slider></app-slider>
<app-welcome></app-welcome>

<!--<ng-container *ngIf="webType === 'ESPAÑOL'; else gallego">-->
<app-offers></app-offers>
<!--</ng-container>-->

<!--<ng-template #gallego>
  <app-gallegohome></app-gallegohome>
</ng-template>-->

<app-sorteos></app-sorteos>
<app-aboutus></app-aboutus>
<app-appmobile></app-appmobile>
