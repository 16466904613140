<section id="centralita">
  <div class="container">
    <div class="welcome">
      <i
        class="bi bi-headphones text-gradient"
        data-aos="fade-in"
        data-aos-duration="1500"
      ></i>
      <h2 data-aos="fade-in" data-aos-duration="1500">
        <strong style="color: #6cace4">CENTRALIÑA VIRTUAL</strong> <br />para
        empresas de todos os tamaños
      </h2>

      <div class="divider" data-aos="fade-left" data-aos-duration="2500"></div>

      <p>
        Profesionaliza a atención telefónica do teu negocio e outorga ás túas
        comunicacións <br />
        un plus de valor engadido con +20 servizos adicionais. <br />
        A nosa Central de Voz IP é unha solución sinxela e competitiva con todas
        as funcionalidades <br />
        necesarias para a PEME que, ademais, cubrirá as vosas necesidades <br />
        cunha xestión integral que repercutirá na optimización do tempo e a
        redución de custos. <br />
      </p>
    </div>
    <div class="schema" data-aos="fade-down">
      <div class="schema-boxes">
        <img
          class="schema-img"
          src="../../../assets/IMG/Logos-Celta/celta-mobile.png"
          alt="celtamobile"
          style="max-width: 360px"
        />
      </div>

      <div class="icondown" data-aos="fade-down">
        <i class="bi bi-arrow-down"></i>
      </div>

      <div class="schema-boxes" data-aos="fade-down">
        <img
          class="schema-img"
          src="../../../../assets/IMG/infoempresas/img-router.png"
          alt=""
        />
        <h3>Router red GSM</h3>
      </div>

      <div class="icondown" data-aos="fade-down">
        <i class="bi bi-arrow-down"></i>
      </div>

      <div class="schema-services" data-aos="fade-down">
        <div class="schema-boxes">
          <img
            src="../../../../assets/IMG/infoempresas/img-plataformas.png"
            alt=""
            class="schema-img-small"
          />
          <h3>Terminais IP fixos e sen fíos</h3>
        </div>

        <div class="schema-boxes">
          <img
            src="../../../../assets/IMG/infoempresas/img-IP.png"
            alt=""
            class="schema-img-small"
          />
          <h3>Extensións rexistradas en Softphone</h3>
        </div>

        <div class="schema-boxes">
          <img
            src="../../../../assets/IMG/infoempresas/img-sim.png"
            alt=""
            class="schema-img-small"
          />
          <h3>
            Enrutado e/ou transferencia de chamadas a móbiles internos ou
            externos
          </h3>
        </div>

        <!-- <div class="schema-boxes">
                    <img src="../../../../assets/IMG/infoempresas/img-puzzle.png" alt="" class="schema-img-small">
                    <h3>CRM ERP</h3>
                </div> -->
      </div>
    </div>

    <div class="iconsouth" data-aos="fade-in">
      <mat-icon class="icon-south">south</mat-icon>
    </div>

    <!-- servicios -->
    <div class="services">
      <div class="services-boxes" data-aos="fade-right">
        <div class="icon-box">
          <mat-icon>insights</mat-icon>
        </div>
        <div class="title">
          <span> Disminución de custos </span>
        </div>
        <p>
          Coa nosa solución de central na nube, poderá diminuír os custos en
          telefonía, dando solución ás comunicacións do seu negocio cun servizo
          diferenciado e de calidade.
        </p>
      </div>

      <div class="services-boxes" data-aos="fade-left">
        <div class="icon-box">
          <mat-icon>bolt</mat-icon>
        </div>
        <div class="title">
          <span> Rápido e sinxelo </span>
        </div>
        <p>
          Dunha forma rápida e sinxela, vostede poderá comezar a gozar na súa
          empresa de todas as funcionalidades da central virtual.
        </p>
      </div>

      <div class="services-boxes" data-aos="fade-right">
        <div class="icon-box">
          <mat-icon>all_inclusive</mat-icon>
        </div>
        <div class="title">
          <span> Xestión integral </span>
        </div>
        <p>
          Ocupámonos da xestión de portabilidade das súas numeracións ou se o
          cliente o desexa, pode solicitarnos numeracións novas.
        </p>
      </div>

      <div class="services-boxes" data-aos="fade-left">
        <div class="icon-box">
          <mat-icon>call_split</mat-icon>
        </div>
        <div class="title">
          <span> Sen portabilidade </span>
        </div>
        <p>
          A súa empresa poderá integrar todas as funcionalidades avanzadas dunha
          Central IP co seu sistema de telefonía móbil, sen necesidade de portar
          números ou cambios de operador móbil.
        </p>
      </div>
    </div>

    <div class="iconsouth" data-aos="fade-down">
      <mat-icon class="icon-south">south</mat-icon>
    </div>

    <!-- servicios extras -->
    <div class="extras">
      <div class="extra align-end">
        <div *ngFor="let item of list1" class="items" data-aos="fade-right">
          <h2 class="text-end">
            {{ item.text }}
          </h2>
          <mat-icon class="text-gradient icon">{{ item.icon }}</mat-icon>
        </div>
      </div>
      <div class="extra">
        <div *ngFor="let item of list2" class="items" data-aos="fade-left">
          <mat-icon class="text-gradient icon">{{ item.icon }}</mat-icon>
          <h2>
            {{ item.text }}
          </h2>
        </div>
      </div>
    </div>
  </div>
</section>
