import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-infoempresas",
  templateUrl: "./infoempresas.component.html",
  styleUrls: ["./infoempresas.component.scss"],
})
export class InfoempresasComponent implements OnInit {
  list1 = [
    {
      text: "Operadora automática",
      icon: "call_split",
    },
    {
      text: "Monitoraxe de colas",
      icon: "assistant",
    },
    {
      text: "Grupos de chamadas con salto a outras extensións para cando está ocupado",
      icon: "call",
    },
    {
      text: "Gravación de chamadas",
      icon: "mark_chat_unread",
    },
    {
      text: "Multiconferencia",
      icon: "settings_input_composite",
    },
    {
      text: "Informes de chamadas",
      icon: "inventory",
    },
    {
      text: "Mensaxes personalizados ao 100% (Voz, benvida, horario etc)",
      icon: "construction",
    },
    {
      text: "Xestión de chamadas en espera",
      icon: "app_settings_alt",
    },
    {
      text: "Configuración os teus horarios e calendario",
      icon: "event_available",
    },
    {
      text: "Transferencia de chamadas",
      icon: "voicemail",
    },
    {
      text: "Ofrecemos música en espera personalizada e/ou predeterminada",
      icon: "library_music",
    },
    {
      text: "Multidispositivo (app iOS/Android, ordenador, telefono IP)",
      icon: "devices",
    },
  ];

  list2 = [
    {
      text: "Call Center",
      icon: "settings_phone",
    },
    {
      text: "Chamada directa a extensión",
      icon: "settings_phone",
    },

    {
      text: "Mensaxes personalizados ao 100% (Voz, benvida, horario etc)",
      icon: "email",
    },
    {
      text: "Rexistro de chamada con filtro por destinos/horarios/datas/extensións",
      icon: "list_alt",
    },
    {
      text: "Base de datos de contactos común para todos os usuarios da central (Excell)",
      icon: "save",
    },
    {
      text: "Proporcionamos numeración de rede intelixente (900/800)",
      icon: "dialer_sip",
    },
    {
      text: "Integración fixo-móbil",
      icon: "dialpad",
    },
    {
      text: "Soporte técnico atendido por persoas 24/7",
      icon: "supervised_user_circle",
    },
    {
      text: "Horarios por grupos de chamadas e/ou departamento",
      icon: "schedule",
    },
    {
      text: "Ofrecemos servizos de FAX de todo tipo",
      icon: "message",
    },
    {
      text: "Locucións de benvida/triaxe -> Fóra de horario -> Selección por teclado",
      icon: "speaker_phone",
    },
    {
      text: "Histórico por defecto das gravacións de 3 meses (ampliable a un ano)",
      icon: "featured_play_list",
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
