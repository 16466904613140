<section>
  <div class="description">
    <span class="description-text"
      ><strong style="color: #6cace4">Servizos personalizados </strong>para
      empresas de todos os tamaños
    </span>
  </div>
  <div class="container">
    <div class="item">
      <i class="bi bi-headphones text-gradient icon"></i>
      <h3 class="title">CENTRALIÑA CLOUD</h3>
      <p class="text">
        Servizo Global de Voz IP de desenvolvemento propio para empresas,
        ofrecendo dende a compra e implantación da tecnoloxía necesaria, ata o
        soporte e mantemento fundamentais. Sen recorrer a terceiros.
      </p>

      <div class="letter-container">
        <button
          mat-stroked-button
          class="mas-info"
          (click)="goToAnchor('centralita')"
        >
          + info
        </button>
      </div>
    </div>
    <div class="item">
      <i class="bi bi-wifi text-gradient icon"></i>
      <h3 class="title">INTERNET</h3>
      <p class="text">
        Fibra. Internet 4G. 5G. Colaboramos cos principais operadores nacionais
        de telecomunicacións para ofrecer aos nosos clientes as mellores
        experiencias de conexión.
      </p>
    </div>

    <div class="item">
      <i class="bi bi-people-fill text-gradient icon"></i>
      <h3 class="title">SOFTWARE DE GESTIÓN</h3>
      <p class="text">
        Dispoñemos dun software de xestión para empresas, de desenvolvemento
        propio, que facilitará as principais funcións e relacións cos
        empregados.
      </p>

      <!--  <div class="letter-container">
                <button mat-stroked-button class="mas-info" (click)="goToAnchor('software')">+ info</button>
            </div>-->
    </div>

    <div class="item">
      <i class="bi bi-phone text-gradient icon"></i>
      <h3 class="title">MÓBIL</h3>
      <p class="text">
        Somos operador móbil virtual, coa tecnoloxía 4G e cobertura do 99% en
        territorio nacional. Gran servizo e calidade ao mellor prezo.
      </p>
    </div>
    <div class="item">
      <i class="bi bi-shield-check text-gradient icon"></i>
      <h3 class="title">SEGURIDADE</h3>
      <p class="text">
        Traballamos cos mellores fabricantes para obter, no ámbito da
        seguridade, as solucións máis profesionais para os nosos clientes a
        nivel de Firewall.
      </p>
    </div>

    <div class="item">
      <i class="bi bi-cpu text-gradient icon"></i>
      <h3 class="title">HARDWARE</h3>
      <p class="text">
        Temos a selección dos mellores dispositivos móbiles e equipos
        electrónicos de todo tipo para que os nosos clientes teñan ao seu
        alcance a mellor selección do mercado. Somos partner especializado en
        dispositivos OPPO.
      </p>
    </div>
    <div class="item">
      <i class="bi bi-chat-left-text text-gradient icon"></i>
      <h3 class="title">SMS MASIVOS</h3>
      <p class="text">
        Plataforma para enviar SMS masivos para xestionar mellor a súa carteira
        clientes.
      </p>
    </div>
    <div class="item">
      <i class="bi bi-terminal text-gradient icon"></i>
      <h3 class="title">DESARROLLO</h3>
      <p class="text">
        O noso equipo de enxeñeiros traballa todos os días no desenvolvemento de
        novos software para satisfacer as necesidades dos nosos clientes.
        adaptámonos 100% para ti.
      </p>
    </div>
  </div>
</section>
<app-infoempresas id="centralita"></app-infoempresas>
<app-infointernet id="software"></app-infointernet>
